import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import assetsByGame from "./assetsByGame";

const GameLoader = ({ children }) => {
  const { gameId } = useParams(); 
  const [isAssetsLoaded, setIsAssetsLoaded] = useState(false);

  useEffect(() => {
    const gameAssets = assetsByGame[gameId];
    if (!gameAssets) {
      // Если ассеты для игры не найдены, можно сразу завершить загрузку
      setIsAssetsLoaded(true);
      return;
    }

    const loadImage = (src) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = () => reject(src);
      });

    const loadAudio = (src) =>
      new Promise((resolve, reject) => {
        const audio = new Audio(src);
        audio.preload = "auto";
        audio.onloadeddata = resolve;
        audio.onerror = () => reject(src);
      });

    const imagePromises = Object.values(gameAssets.images).map((src) =>
      loadImage(src)
    );
    const audioPromises = Object.values(gameAssets.sounds).map((src) =>
      loadAudio(src)
    );

    Promise.all([...imagePromises, ...audioPromises])
      .then(() => setIsAssetsLoaded(true))
      .catch((error) => {
        console.error("Ошибка загрузки ассетов:", error);
        setIsAssetsLoaded(true);
      });
  }, [gameId]);

  if (!isAssetsLoaded) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ClipLoader size={50} color="#00BFFF" />
        <p
          style={{
            marginTop: "10px",
            fontSize: "18px",
            color: "#555",
            fontStyle: "Nunito Regular",
          }}
        >
          Загружаем материалы...
        </p>
      </div>
    );
  }

  return children;
};

export default GameLoader;

import { useParams } from "react-router-dom";
import NotFound from "../not-found/not-found";
import { lazy, Suspense } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import GameLoader from "../loader/game-loader-v2";

const gameComponentsMap = {
  rabbit: lazy(() => import("../games-folder/rabbit-game/rabbit-game")),
  associations: lazy(() => import("../games-folder/associations/associations")),
  frogs: lazy(() => import("../games-folder/frogs-game/frogs-game")),
  fish: lazy(() => import("../games-folder/fish-game/fish-game")),
  "smaller-bigger": lazy(() =>
    import("../games-folder/bigger-smaller/bigger-smaller-game")
  ),
  seasons: lazy(() => import("../games-folder/seasons-game/seasons-game")),
  "artist-mistakes": lazy(() =>
    import("../games-folder/artist-mistakes/artist-mistakes-game")
  ),
  "find-in-room": lazy(() =>
    import("../games-folder/find-in-room-game/find-in-room")
  ),
  "animals-in-forest": lazy(() =>
    import("../games-folder/animals-in-forest/animals-in-forest")
  ),
  "animals-hot-countries": lazy(() =>
    import("../games-folder/animals-hot-countries/animals-hot-countries")
  ),
  "guess-action": lazy(() =>
    import("../games-folder/guess-action/guess-action")
  ),
  "animals-at-farm": lazy(() =>
    import("../games-folder/animals-farm/animals-farm")
  ),
  "find-in-park": lazy(() =>
    import("../games-folder/find-in-park/find-in-park")
  ),
  dinosaur: lazy(() => import("../games-folder/dinosaur/dinosaur")),
  "home-appliances": lazy(() =>
    import("../games-folder/home-appliances/home-appliances")
  ),
  "animals-sounds": lazy(() =>
    import("../games-folder/animals-sounds/animals-sounds")
  ),
  "countryside-sounds": lazy(() =>
    import("../games-folder/countryside/countryside")
  ),
  "street-sounds": lazy(() =>
    import("../games-folder/street-sounds/street-sounds")
  ),
  "transport-sounds": lazy(() =>
    import("../games-folder/transport-sounds/transport-sounds")
  ),
  "nature-sounds": lazy(() =>
    import("../games-folder/nature-sounds/nature-sounds")
  ),
  "emotions-sounds": lazy(() =>
    import("../games-folder/emotions-sounds/emotions-sounds")
  ),
  "birds-sounds": lazy(() =>
    import("../games-folder/birds-sounds/birds-sounds")
  ),
  "musical-instruments-sounds": lazy(() =>
    import(
      "../games-folder/musical-instruments-sounds/musical-instruments-sounds"
    )
  ),
  "insects-sounds": lazy(() =>
    import("../games-folder/insects-sounds/insects-sounds")
  ),
  "animals-sounds-tutorial": lazy(() =>
    import(
      "../games-folder/tutorials/animals-sounds-tutorial/animals-sounds-tutorial"
    )
  ),
  "home-appliances-tutorial": lazy(() =>
    import(
      "../games-folder/tutorials/home-appliances-tutorial/home-appliances-tutorial"
    )
  ),
  "countryside-tutorial": lazy(() =>
    import(
      "../games-folder/tutorials/countryside-tutorial/countryside-tutorial"
    )
  ),
  "street-sounds-tutorial": lazy(() =>
    import(
      "../games-folder/tutorials/street-sounds-tutorial/street-sounds-tutorial"
    )
  ),
  "transport-sounds-tutorial": lazy(() =>
    import(
      "../games-folder/tutorials/transport-sounds-tutorial/transport-sounds-tutorial"
    )
  ),
  "nature-sounds-tutorial": lazy(() =>
    import(
      "../games-folder/tutorials/nature-sounds-tutorial/nature-sounds-tutorial"
    )
  ),
  "emotions-sounds-tutorial": lazy(() =>
    import(
      "../games-folder/tutorials/emotions-sounds-tutorial/emotions-sounds-tutorial"
    )
  ),
  "birds-sounds-tutorial": lazy(() =>
    import(
      "../games-folder/tutorials/birds-sounds-tutorial/birds-sounds-tutorial"
    )
  ),
  "musical-instruments-sounds-tutorial": lazy(() =>
    import(
      "../games-folder/tutorials/musical-instruments-sounds-tutorial/musical-instruments-sounds-tutorial"
    )
  ),
  "insects-sounds-tutorial": lazy(() =>
    import(
      "../games-folder/tutorials/insects-sounds-tutorial/insects-sounds-tutorial"
    )
  ),
  "math-figure-one": lazy(() => import("../games-folder/math-one/math-one")),
  "math-figure-two": lazy(() => import("../games-folder/math-two/math-two")),
  "math-figure-three": lazy(() =>
    import("../games-folder/math-three/math-three")
  ),
  "math-figure-four": lazy(() => import("../games-folder/math-four/math-four")),
  "math-figure-five": lazy(() => import("../games-folder/math-five/math-five")),
  "math-figure-six": lazy(() => import("../games-folder/math-six/math-six")),
  "math-figure-seven": lazy(() =>
    import("../games-folder/math-seven/math-seven")
  ),
  "math-figure-eight": lazy(() =>
    import("../games-folder/math-eight/math-eight")
  ),
  "math-figure-nine": lazy(() => import("../games-folder/math-nine/math-nine")),
  "math-figure-ten": lazy(() => import("../games-folder/math-ten/math-ten")),
  "angry-goat": lazy(() => import("../games-folder/angry-goat/angry-goat")),
  "magic-lamps": lazy(() => import("../games-folder/magic-lamps/magic-lamps")),
  "bumble-bee": lazy(() => import("../games-folder/bumblebee/bumblebee")),
  "what-in-snakes-hole": lazy(() =>
    import("../games-folder/snakes-hole/snakes-hole")
  ),
  "dating-with-zhuzha": lazy(() =>
    import("../games-folder/dating-with-zhuzha/dating-with-zhuzha-main")
  ),
  "acorn-with-surprise": lazy(() =>
    import("../games-folder/acorn-with-surprise/acorn-with-surprise")
  ),
  "picking-fruits": lazy(() =>
    import("../games-folder/picking-fruits/picking-fruits")
  ),
  "what-owl-found-in-forest": lazy(() =>
    import("../games-folder/what-owl-found-in-forest/owl-found-in-forest")
  ),
  "studying-circle": lazy(() =>
    import("../games-folder/studying-circle/studying-circle")
  ),
  "studying-square": lazy(() =>
    import("../games-folder/studying-square/studying-square")
  ),
  "studying-triangle": lazy(() =>
    import("../games-folder/studying-triangle/studying-triangle")
  ),
  "studying-rectangle": lazy(() =>
    import("../games-folder/studying-rectangle/studying-rectangle")
  ),
  "studying-rhomb": lazy(() =>
    import("../games-folder/studying-rhomb/studying-rhomb")
  ),
  "studying-oval": lazy(() =>
    import("../games-folder/studying-oval/studying-oval")
  ),
  "ball-dancing": lazy(() =>
    import("../games-folder/ball-dancing/ball-dancing")
  ),
  "create-human": lazy(() =>
    import("../games-folder/create-a-human/create-a-human")
  ),
  "create-face": lazy(() => import("../games-folder/create-face/create-face")),
  "watch-cinema": lazy(() =>
    import("../games-folder/watch-cinema/watch-cinema")
  ),
  "most-attentive": lazy(() =>
    import("../games-folder/most-attentive/most-attentive")
  ),
  "look-binoculars": lazy(() =>
    import("../games-folder/look-binoculars/look-binoculars")
  ),
  "talking-projector": lazy(() =>
    import("../games-folder/talking-projector/talking-projector")
  ),
  "lossing-boy": lazy(() => import("../games-folder/lossing-boy/lossing-boy")),
  "grandma-harvest": lazy(() =>
    import("../games-folder/grandma-harvest/grandma-harves-main")
  ),
  "diamonds-with-surprise": lazy(() =>
    import("../games-folder/diamonds-with-surprise/diamonds-with-surprise")
  ),
  "shopping-ahead": lazy(() =>
    import("../games-folder/shopping-ahead/shopping-ahead-main")
  ),
  "lost-things": lazy(() => import("../games-folder/lost-things/lost-things")),
  lockers: lazy(() => import("../games-folder/lockers/lockers")),
  "fly-traveller": lazy(() =>
    import("../games-folder/fly-traveller/fly-traveller-main")
  ),
  "hungry-fly": lazy(() => import("../games-folder/hungry-fly/hungry-fly")),
  "find-turtles": lazy(() =>
    import("../games-folder/find-turtles/find-turtles")
  ),
  "tea-party": lazy(() => import("../games-folder/tea-party/tea-party")),
  dobble: lazy(() => import("../games-folder/dobble/dobble")),
  "rabbit-in-guests": lazy(() =>
    import("../games-folder/rabbit-in-guests/rabbit-in-guests")
  ),
  "christmas-socks": lazy(() =>
    import("../games-folder/christmas-socks/christmas-socks")
  ),
  "lizards-and-tails": lazy(() =>
    import("../games-folder/lizards-and-tails/lizards-and-tails")
  ),
  "fish-market": lazy(() => import("../games-folder/fish-market/fish-market")),
  "witch-spells": lazy(() =>
    import("../games-folder/witch-spells/witch-spells")
  ),
  "play-piano": lazy(() => import("../games-folder/play-piano/play-piano")),
  "heron-in-swamp": lazy(() =>
    import("../games-folder/heron-in-swamp/heron-in-swamp")
  ),
  "big-manufacture": lazy(() =>
    import("../games-folder/big-manufacture/big-manufacture")
  ),
  "shopping-list": lazy(() =>
    import("../games-folder/shopping-list/shopping-list")
  ),
  "acquaintance-with-mushrooms": lazy(() =>
    import("../games-folder/acquaintance-mushrooms/acquaintance-mushrooms-main")
  ),
  "forest-life": lazy(() =>
    import("../games-folder/forest-life/forest-life-main")
  ),
  "furniture-house": lazy(() =>
    import("../games-folder/furniture-house/furniture-house-main")
  ),
  "lion-hunt": lazy(() => import("../games-folder/lion-hunt/lion-hunt")),
  "melting-ice": lazy(() => import("../games-folder/melting-ice/melting-ice")),
  "slepless-night": lazy(() =>
    import("../games-folder/slepless-night/slepless-night")
  ),
  "lets-play-with-toys": lazy(() =>
    import("../games-folder/lets-play-with-toys/lets-play-with-toys")
  ),
  "chicken-ko-ko": lazy(() =>
    import("../games-folder/chicken-ko-ko/chicken-ko-ko")
  ),
  "tricky-scales": lazy(() =>
    import("../games-folder/tricky-scales/tricky-scales")
  ),
  "help-cooker": lazy(() => import("../games-folder/help-cooker/help-cooker")),
  "sort-purchases-into-bags": lazy(() =>
    import("../games-folder/sort-purchases-into-bags/sort-purchases-into-bags")
  ),
  "dobble-l": lazy(() => import("../games-folder/dobble-l/dobble-l")),
  "heavier-lighter": lazy(() =>
    import("../games-folder/heavier-lighter/heavier-lighter")
  ),
  "dobble-c": lazy(() => import("../games-folder/dobble-c/dobble-c")),

  "secret-letter": lazy(() =>
    import("../games-folder/secret-letter/secret-letter")
  ),
  "dobble-sh": lazy(() => import("../games-folder/dobble-sh/dobble-sh")),

  "lion-and-his-lessons": lazy(() =>
    import("../games-folder/lion-and-his-lessons/lion-and-his-lessons")
  ),

  "dobble-b": lazy(() => import("../games-folder/dobble-b/dobble-b")),

  "dobble-zh": lazy(() => import("../games-folder/dobble-zh/dobble-zh")),

  "dobble-z": lazy(() => import("../games-folder/dobble-z/dobble-z")),
};

const GameRoutes = () => {
  const { gameId } = useParams();
  const LazyGameComponent = gameComponentsMap[gameId];

  if (!LazyGameComponent) {
    return <NotFound />;
  }
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ClipLoader size={50} color="#00BFFF" />
          <p
            style={{
              marginTop: "10px",
              fontSize: "18px",
              color: "#555",
              fontStyle: "Nunito Regular",
            }}
          >
            Загружаем материалы...
          </p>
        </div>
      }
    >
      <GameLoader>
        <LazyGameComponent />
      </GameLoader>
    </Suspense>
  );
};

export default GameRoutes;

// Обходим все файлы с расширениями png, jpg, jpeg, svg, mp3 в папке ../games-folder
const assetsContext = require.context(
  "../games-folder",
  true,
  /\.(png|jpe?g|svg|mp3)$/
);

const assetsByGame = {};

// Пройдемся по всем файлам, найденным через require.context
assetsContext.keys().forEach((key) => {

  const parts = key.split("/");

  const gameFolder = parts[1];

  // Создаем объект для этой игры, если еще не создан
  if (!assetsByGame[gameFolder]) {
    assetsByGame[gameFolder] = { images: {}, sounds: {} };
  }

  // Определяем, относится ли файл к изображениям или звукам, по наличию строки "/images/" или "/sounds/"
  const fileName = parts[parts.length - 1]; 
  const assetValue = assetsContext(key).default || assetsContext(key);

  if (key.includes("/images/")) {
    assetsByGame[gameFolder].images[fileName] = assetValue;
  } else if (key.includes("/sounds/")) {
    assetsByGame[gameFolder].sounds[fileName] = assetValue;
  }
});

export default assetsByGame;

import React, { useState } from "react";
import GamesMenu from "../games-menu/games-menu";
import GamesMenuNew from "../games-menu-new/games-menu-new";
import GamesList from "../games-list/games-list";
import GamesListNew from "../games-list-new/games-list-new";
import styles from "./games.module.css";
import frogImg from "./images/frog.png";
import fishImg from "./images/fish.png";
import bigSmallerImg from "./images/biggerSmaller.png";
import dinoImg from "./images/dino.png";
import farmImg from "./images/farm.png";
import animalsForestImg from "./images/forestAnimals.png";
import animalsHotCountriesImg from "./images/hotCountriesAnimals.png";
import roomImg from "./images/room.png";
import guessActionImg from "./images/actions.png";
import findInParkImg from "./images/findInPark.png";
import artistMistakesImg from "./images/artistMistakes.png";
import seasonsImg from "./images/seasonsMistake.png";
import nonSpeechSoundsAnimals from "./images/animalsSounds.png";
import HomeAppliancesImg from "./images/homeSounds.png";
import CountrysideImg from "./images/countysideSounds.png";
import streetImg from "./images/street.png";
import transportImg from "./images/transport.png";
import natureImg from "./images/nature.png";
import emotionsImg from "./images/emotions.png";
import birdsImg from "./images/birds.png";
import musInstrumentsImg from "./images/musInstr.png";
import insects from "./images/insects.png";
import figureOneImg from "./images/figureOne.png";
import figureTwoImg from "./images/figureTwo.png";
import figureThreeImg from "./images/figureThree.png";
import figureFourImg from "./images/figureFour.png";
import figureFiveImg from "./images/figureFive.png";
import figureSixImg from "./images/figureSix.png";
import figureSevenImg from "./images/figureSeven.png";
import figureEightImg from "./images/figureEight.png";
import figureNineImg from "./images/figureNine.png";
import figureTenImg from "./images/figureTen.png";
import angryGoatImg from "./images/angryGoat.png";
import magicLampsImg from "./images/magicLamps.png";
import bumblebeeImg from "./images/bumblebee.png";
import snakeImg from "./images/snake.png";
import datingWithZhuzhaImg from "./images/datingWithZhuzha.png";
import acornImg from "./images/acorn.png";
import pickingFruitsImg from "./images/pickingFruits.png";
import whatOwlFoundImg from "./images/whatOwlFound.png";
import circleImg from "./images/circle.png";
import squareImg from "./images/square.png";
import triangleImg from "./images/triangle.png";
import rectangleImg from "./images/rectangle.png";
import rhombusImg from "./images/rhomb.png";
import ovalImg from "./images/oval.png";
import rabbitImg from "./images/rabbit.png";
import associationsImg from "./images/associations.png";
import ballDanceImg from "./images/ballDance.png";
import createHumanImg from "./images/createHuman.png";
import createFaceImg from "./images/createFace.png";
import watchCinemaImg from "./images/watchCinema.png";
import mostAttentiveImg from "./images/mostAttentive.png";
import lookBinocularsImg from "./images/lookBinoculars.png";
import talkingProjectorImg from "./images/talkingProjector.png";
import lossingBoyImg from "./images/lossingBoy.png";
import grandmaHarvestImg from "./images/grandmaHarvest.png";
import diamondsWithSurpriseImg from "./images/diamondsWithSurprise.png";
import shoppingAheadImg from "./images/shoppingAhead.png";
import lostThingsImg from "./images/lostThings.png";
import lockersImg from "./images/lockers.png";
import flyTravellerImg from "./images/flyTraveller.png";
import hungryFlyImg from "./images/hungryFly.png";
import findTurtlesImg from "./images/findTurtles.png";
import teaParyImg from "./images/teaParty.png";
import dobbleImg from "./images/dobble.png";
import rabbitInGuestsImg from "./images/rabbit-in-guests.png";
import christmasSocksImg from "./images/christmas-socks.png";
import lizsrdsAndTailsImg from "./images/lizardsAndTails.png";
import fishMarketImg from "./images/fishMarket.png";
import witchSpellsImg from "./images/witchSpells.png";
import playPianoImg from "./images/playPiano.png";
import heronInSwampImg from "./images/heronInSwamp.png";
import bigManufactureImg from "./images/bigManufacture.png";
import shoppingListImg from "./images/shoppingList.png";
import acquaintanceMushroomsImg from "./images/acquaintanceMushrooms.png";
import forestLifeImg from "./images/forestLife.png";
import furnitureHouseImg from "./images/furnitureHouse.png";
import lionHuntImg from "./images/lionHunt.png";
import meltingIceImg from "./images/meltingIce.png";
import sleplessNightImg from "./images/sleplessNight.png";
import musicalSoundsTutorialImg from "./images/musicalSoundsTutorial.png";
import countrysideSoundsTutorialImg from "./images/countrysideSoundsTutorial.png";
import transportSoundsTutorialImg from "./images/transportSoundsTutorial.png";
import animalsSoundsTutorialImg from "./images/animalsSoundsTutorial.png";
import homeApplSoundsTutorialImg from "./images/homeApplSoundsTutorial.png";
import birdsSoundsTutorialImg from "./images/birdsSoundsTutorial.png";
import natureSoundsTutorialImg from "./images/natureSoundsTutorial.png";
import insectsSoundsTutorialImg from "./images/insectsSoundsTutorial.png";
import streetSoundTutorialImg from "./images/streetSoundTutorial.png";
import emotionsSoundTutorialImg from "./images/emotionsSoundTutorial.png";
import letsPlayWithToysImg from "./images/letsPlayWithToys.png";
import chickenKoKoImg from "./images/chickenKoKo.png";
import trickyScalesImg from "./images/trickyScales.png";
import helpCookerImg from "./images/helpCooker.png";
import sortPurchasesIntoBagsImg from "./images/sortPurchasesIntoBags.png";
import dobbleLImg from "./images/dobbleL.png";
import heavierLighterImg from "./images/heavierLighter.png";
import dobbleCImg from "./images/dobbleC.png";
import secretLetterImg from "./images/secretLetter.png";
import dobbleShImg from "./images/dobbleSh.png";
import lionAndHisLessonsImg from "./images/lionAndHisLessons.png";
import dobbleBImg from "./images/dobbleB.png";
import dobbleZhImg from "./images/dobbleZh.png";
import dobbleZImg from "./images/dobbleZ.png";

const Games = () => {
  const [activeCategory, setActiveCategory] = useState({
    category: "Звукопроизношение",
    subcategory: null,
  });
  const gamesData = [
    {
      id: 1001,
      title: "Зайчик и морковка",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Учимся считать от 1 до 10",
      path: "/games/rabbit",
      imageUrl: rabbitImg,
      unique_id: 1001,
    },
    {
      id: 1002,
      title: 'Ассоциации "кто где живет"',
      category: "Память, внимание и логика",
      subcategory: "Логические задачи",
      description:
        "Найти связи между картинками по какому-либо свойству или признаку",
      path: "/games/associations",
      imageUrl: associationsImg,
      unique_id: 1002,
    },
    {
      id: 1,
      title: "Помоги лягушонку добраться до дома",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука 'Л' в слогах",
      path: "/games/frogs",
      imageUrl: frogImg,
      unique_id: 1,
    },
    {
      id: 2,
      title: "Помоги рыбе клоуну найти игрушки",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука 'Р' в начале слова",
      path: "/games/fish",
      imageUrl: fishImg,
      unique_id: 2,
    },
    {
      id: 3,
      title: "Кто больше, кто меньше",
      category: "Математика",
      subcategory: "Сравнение",
      description: "Сравнить два предмета и выбрать правильный ответ",
      path: "/games/smaller-bigger",
      imageUrl: bigSmallerImg,
      unique_id: 3,
    },
    {
      id: 4,
      title: "Автоматизация звука 'Р' Динозавр",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: " Автоматизация звука 'Р' в слогах",
      path: "/games/dinosaur",
      imageUrl: dinoImg,
      unique_id: 4,
    },
    {
      id: 5,
      title: "Домашние животные",
      category: "Развитие речи",
      subcategory: "Лексика",
      description:
        "Ребенку необходимо выбрать животное, назвать его и сказать где оно живёт",
      path: "/games/animals-at-farm",
      imageUrl: farmImg,
      unique_id: 5,
    },
    {
      id: 6,
      title: "Дикие животные",
      category: "Развитие речи",
      subcategory: "Лексика",
      description:
        "Ребенку необходимо выбрать животное, назвать его и сказать где оно живёт",
      path: "/games/animals-in-forest",
      imageUrl: animalsForestImg,
      unique_id: 6,
    },
    {
      id: 7,
      title: "Животные жарких стран",
      category: "Развитие речи",
      subcategory: "Лексика",
      description:
        "Ребенку необходимо выбрать животное, назвать его и сказать где оно живёт",
      path: "/games/animals-of-hot-countries",
      imageUrl: animalsHotCountriesImg,
      unique_id: 7,
    },
    {
      id: 8,
      title: "Найди в комнате",
      category: "Память, внимание и логика",
      subcategory: "Зрительное внимание",
      description: "Найти силуэт и подобрать к нему предмет",
      path: "/games/find-in-room",
      imageUrl: roomImg,
      unique_id: 8,
    },
    {
      id: 9,
      title: "Угадай действие",
      category: "Развитие речи",
      subcategory: "Связаная речь",
      description:
        "Послушать задание и выбрать правильную картинку. Можно попросить ребенка назвать что делают на картинках персонажи",
      path: "/games/guess-action",
      imageUrl: guessActionImg,
      unique_id: 9,
    },
    {
      id: 10,
      title: "Угадай действие",
      category: "Слуховое восприятие",
      subcategory: "Фонетический",
      description:
        "Послушать задание. Можно попросить ребенка назвать что делают на картинках персонажи",
      path: "/games/guess-action",
      imageUrl: guessActionImg,
      unique_id: 9,
    },
    {
      id: 11,
      title: "Найди в парке",
      category: "Развитие речи",
      subcategory: "Связаная речь",
      description:
        "Послушать инструкцию и выбрать правильный ответ. Можно попросить ребенка назвать что делают на картинках персонажи",
      path: "/games/find-in-the-park",
      imageUrl: findInParkImg,
      unique_id: 10,
    },
    {
      id: 12,
      title: "Найди в парке",
      category: "Слуховое восприятие",
      subcategory: "Фонетический",
      description:
        "Послушать инструкцию и выбрать правильный ответ. Можно попросить ребенка назвать что делают на картинках персонаж",
      path: "/games/find-in-the-park",
      imageUrl: findInParkImg,
      unique_id: 10,
    },
    {
      id: 13,
      title: "Ошибки художника",
      category: "Память, внимание и логика",
      subcategory: "Зрительное внимание",
      description: "Найти ошибку в картинке и сказать что неправильно",
      path: "/games/artist-mistakes",
      imageUrl: artistMistakesImg,
      unique_id: 11,
    },
    {
      id: 14,
      title: "Времена года",
      category: "Окружающий мир",
      subcategory: "Явления природы",
      description: "Найти ошибку в картинке и сказать что неправильно",
      path: "/games/seasons",
      imageUrl: seasonsImg,
      unique_id: 12,
    },
    {
      id: 15,
      title: "Времена года",
      category: "Память, внимание и логика",
      subcategory: "Логические задачи",
      description: "Найти ошибку в картинке и сказать что неправильно",
      path: "/games/seasons",
      imageUrl: seasonsImg,
      unique_id: 12,
    },
    {
      id: 16,
      title: "Времена года",
      category: "Память, внимание и логика",
      subcategory: "Зрительное внимание",
      description: "Найти ошибку в картинке и сказать что неправильно",
      path: "/games/seasons",
      imageUrl: seasonsImg,
      unique_id: 12,
    },
    {
      id: 17,
      title: "Неречевые звуки (животные)",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/animals-sounds",
      imageUrl: nonSpeechSoundsAnimals,
      unique_id: 13,
    },
    {
      id: 18,
      title: "Бытовые звуки",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/home-appliances",
      imageUrl: HomeAppliancesImg,
      unique_id: 14,
    },
    {
      id: 19,
      title: "За городом",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/countryside-sounds",
      imageUrl: CountrysideImg,
      unique_id: 15,
    },
    {
      id: 20,
      title: "На улице",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/street-sounds",
      imageUrl: streetImg,
      unique_id: 16,
    },
    {
      id: 21,
      title: "Транспорт",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/transport-sounds",
      imageUrl: transportImg,
      unique_id: 17,
    },
    {
      id: 22,
      title: "Природа",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/nature-sounds",
      imageUrl: natureImg,
      unique_id: 18,
    },
    {
      id: 23,
      title: "Звуки людей (эмоции)",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/emotions-sounds",
      imageUrl: emotionsImg,
      unique_id: 19,
    },
    {
      id: 24,
      title: "Птицы",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/birds-sounds",
      imageUrl: birdsImg,
      unique_id: 20,
    },
    {
      id: 25,
      title: "Музыкальные инструменты",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/musical-instruments-sounds",
      imageUrl: musInstrumentsImg,
      unique_id: 21,
    },
    {
      id: 26,
      title: "Насекомые",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description: "Послушать звук и выбрать правильную картинку",
      path: "/games/insects-sounds",
      imageUrl: insects,
      unique_id: 22,
    },
    {
      id: 27,
      title: "Знакомство с цифрой 1",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-one",
      imageUrl: figureOneImg,
      unique_id: 23,
    },
    {
      id: 28,
      title: "Знакомство с цифрой 2",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-two",
      imageUrl: figureTwoImg,
      unique_id: 24,
    },
    {
      id: 29,
      title: "Знакомство с цифрой 3",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-three",
      imageUrl: figureThreeImg,
      unique_id: 25,
    },
    {
      id: 30,
      title: "Знакомство с цифрой 4",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-four",
      imageUrl: figureFourImg,
      unique_id: 26,
    },
    {
      id: 31,
      title: "Знакомство с цифрой 5",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-five",
      imageUrl: figureFiveImg,
      unique_id: 27,
    },
    {
      id: 32,
      title: "Знакомство с цифрой 6",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-six",
      imageUrl: figureSixImg,
      unique_id: 28,
    },
    {
      id: 33,
      title: "Знакомство с цифрой 7",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-seven",
      imageUrl: figureSevenImg,
      unique_id: 29,
    },
    {
      id: 34,
      title: "Знакомство с цифрой 8",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-eight",
      imageUrl: figureEightImg,
      unique_id: 30,
    },
    {
      id: 35,
      title: "Знакомство с цифрой 9",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-nine",
      imageUrl: figureNineImg,
      unique_id: 31,
    },
    {
      id: 36,
      title: "Знакомство с числом 10",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Внимательно послушайте задания и выполните инструкции",
      path: "/games/math-figure-ten",
      imageUrl: figureTenImg,
      unique_id: 32,
    },
    {
      id: 37,
      title: "Злой козлик",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука “Б”",
      path: "/games/angry-goat",
      imageUrl: angryGoatImg,
      unique_id: 33,
    },
    {
      id: 38,
      title: "Волшебные лампы",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука “Л” в начале слова",
      path: "/games/magic-lamps",
      imageUrl: magicLampsImg,
      unique_id: 34,
    },

    // ВНИМАНИЕ! ИГРА ДОБАВЛЕНА ПОЗЖЕ, ПОЭТОМУ ID-51, обрати внимание на unique_id
    {
      id: 51,
      title: "Что на балу лишнее?",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звук “Б” в начале слова",
      path: "/games/ball-dancing",
      imageUrl: ballDanceImg,
      unique_id: 35,
    },

    // ВНИМАНИЕ! ИГРА ДОБАВЛЕНА ПОЗЖЕ, ПОЭТОМУ ID-52, обрати внимание на unique_id
    {
      id: 52,
      title: "Что на балу лишнее?",
      category: "Память, внимание и логика",
      subcategory: "Зрительное внимание",
      description: "Автоматизация звук “Б” в начале слова",
      path: "/games/ball-dancing",
      imageUrl: ballDanceImg,
      unique_id: 35,
    },

    {
      id: 39,
      title: "Помоги шмелю покушать",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука “Ш” в слогах",
      path: "/games/help-bumblebee-eat",
      imageUrl: bumblebeeImg,
      unique_id: 36,
    },
    {
      id: 40,
      title: "Что у змеи в норе",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука “Ш” в начале слова",
      path: "/games/what-in-snakes-hole",
      imageUrl: snakeImg,
      unique_id: 37,
    },
    {
      id: 41,
      title: "Свидание с подругой Жужжой",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука “Ж” в слогах",
      path: "/games/dating-with-zhuzha",
      imageUrl: datingWithZhuzhaImg,
      unique_id: 38,
    },

    {
      id: 42,
      title: "Желудь с сюрпризом",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука “Ж” в начале слова",
      path: "/games/acorn-with-surprise",
      imageUrl: acornImg,
      unique_id: 39,
    },
    {
      id: 43,
      title: "Собираем фрукты",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука “С” в слогах",
      path: "/games/picking-fruits",
      imageUrl: pickingFruitsImg,
      unique_id: 40,
    },
    {
      id: 44,
      title: "Что сова нашла в лесу",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука “С” в начале слова",
      path: "/games/what-owl-found-in-forest",
      imageUrl: whatOwlFoundImg,
      unique_id: 41,
    },
    {
      id: 45,
      title: "Изучаем круг",
      category: "Математика",
      subcategory: "Фигуры",
      description: "Знакомство с кругом",
      path: "/games/studying-circle",
      imageUrl: circleImg,
      unique_id: 42,
    },
    {
      id: 46,
      title: "Изучаем квадрат",
      category: "Математика",
      subcategory: "Фигуры",
      description: "Знакомство с квадратом",
      path: "/games/studying-square",
      imageUrl: squareImg,
      unique_id: 43,
    },
    {
      id: 47,
      title: "Изучаем треугольник",
      category: "Математика",
      subcategory: "Фигуры",
      description: "Знакомство с треугольником",
      path: "/games/studying-triangle",
      imageUrl: triangleImg,
      unique_id: 44,
    },
    {
      id: 48,
      title: "Изучаем прямоугольник",
      category: "Математика",
      subcategory: "Фигуры",
      description: "Знакомство с прямоугольником",
      path: "/games/studying-rectangle",
      imageUrl: rectangleImg,
      unique_id: 45,
    },
    {
      id: 49,
      title: "Изучаем ромб",
      category: "Математика",
      subcategory: "Фигуры",
      description: "Знакомство с ромбом",
      path: "/games/studying-rhomb",
      imageUrl: rhombusImg,
      unique_id: 46,
    },
    {
      id: 50,
      title: "Изучаем овал",
      category: "Математика",
      subcategory: "Фигуры",
      description: "Знакомство с овалом",
      path: "/games/studying-oval",
      imageUrl: ovalImg,
      unique_id: 47,
    },

    // ЗДЕСЬ СЛЕДУЮЩИЙ ID - 53, так как 51 и 52 были отданы балу, который имеет unique_id 35

    {
      id: 53,
      title: "Собери человека",
      category: "Окружающий мир",
      subcategory: "Мир человека",
      description: "Знакомство с телом человека",
      path: "/games/create-human",
      imageUrl: createHumanImg,
      unique_id: 51,
    },

    {
      id: 54,
      title: "Собери лицо",
      category: "Окружающий мир",
      subcategory: "Мир человека",
      description: "Знакомство с телом человека",
      path: "/games/create-face",
      imageUrl: createFaceImg,
      unique_id: 52,
    },

    {
      id: 55,
      title: "Просмотр кино",
      category: "Слуховое восприятие",
      subcategory: "Фонематический слух",
      description: "Развитие слухо-речевой памяти",
      path: "/games/watch-cinema",
      imageUrl: watchCinemaImg,
      unique_id: 53,
    },

    {
      id: 56,
      title: "Кто самый внимателный?",
      category: "Развитие речи",
      subcategory: "Грамматика",
      description: "Изучение родительного падежа",
      path: "/games/most-attentive",
      imageUrl: mostAttentiveImg,
      unique_id: 54,
    },

    {
      id: 57,
      title: "Посмотри в бинокль",
      category: "Развитие речи",
      subcategory: "Грамматика",
      description: "Изучение винительного падежа",
      path: "/games/look-binoculars",
      imageUrl: lookBinocularsImg,
      unique_id: 55,
    },

    {
      id: 58,
      title: "Говорящий проектор",
      category: "Память, внимание и логика",
      subcategory: "Исключение лишнего",
      description: "Найти лишний предмет и объяснить почему именно он",
      path: "/games/talking-projector",
      imageUrl: talkingProjectorImg,
      unique_id: 56,
    },

    {
      id: 59,
      title: "Мальчик потеряшка",
      category: "Развитие речи",
      subcategory: "Лексика",
      description: "Изучение предложного падежа",
      path: "/games/lossing-boy",
      imageUrl: lossingBoyImg,
      unique_id: 64,
    },

    {
      id: 60,
      title: "Бабушкин урожай",
      category: "Звукопроизношение",
      subcategory: "Дифференциация звуков",
      description: "Дифференциация 'В' и 'Вь', творительный падеж",
      path: "/games/grandma-harvest",
      imageUrl: grandmaHarvestImg,
      unique_id: 65,
    },

    {
      id: 61,
      title: "Бабушкин урожай",
      category: "Развитие речи",
      subcategory: "Лексика",
      description: "Дифференциация 'В' и 'Вь', творительный падеж",
      path: "/games/grandma-harvest",
      imageUrl: grandmaHarvestImg,
      unique_id: 65,
    },

    {
      id: 62,
      title: "Алмазы с сюрпризом",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука 'З' в середине слова",
      path: "/games/diamonds-with-surprise",
      imageUrl: diamondsWithSurpriseImg,
      unique_id: 67,
    },

    {
      id: 63,
      title: "За покупками вперед!",
      category: "Слуховое восприятие",
      subcategory: "Фонематический слух",
      description: "Развитие слухо-речевой памяти",
      path: "/games/shopping-ahead",
      imageUrl: shoppingAheadImg,
      unique_id: 62,
    },
    {
      id: 64,
      title: "За покупками вперед!",
      category: "Математика",
      subcategory: "Цифры, счет",
      description: "Развитие слухо-речевой памяти",
      path: "/games/shopping-ahead",
      imageUrl: shoppingAheadImg,
      unique_id: 62,
    },
    {
      id: 65,
      title: "Потерянные вещи",
      category: "Развитие речи",
      subcategory: "Лексика",
      description: "Изучение родительного падежа",
      path: "/games/lost-things",
      imageUrl: lostThingsImg,
      unique_id: 63,
    },
    {
      id: 66,
      title: "Замочки",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description:
        "Автоаматизация звука 'З' в начале слова, словосочетания, слуховое внимание и творительный падеж",
      path: "/games/lockers",
      imageUrl: lockersImg,
      unique_id: 66,
    },
    {
      id: 67,
      title: "Замочки",
      category: "Развитие речи",
      subcategory: "Лексика",
      description:
        "Автоаматизация звука 'З' в начале слова, словосочетания, слуховое внимание и творительный падеж",
      path: "/games/lockers",
      imageUrl: lockersImg,
      unique_id: 66,
    },
    {
      id: 68,
      title: "Муха путешественница",
      category: "Развитие речи",
      subcategory: "Грамматика",
      description: "Изучение дательного падежа",
      path: "/games/fly-traveller",
      imageUrl: flyTravellerImg,
      unique_id: 68,
    },
    {
      id: 69,
      title: "Голодная муха",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Звук 'З' в слогах",
      path: "/games/hungry-fly",
      imageUrl: hungryFlyImg,
      unique_id: 69,
    },
    {
      id: 70,
      title: "Найди черепашек",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Звук 'Ч' в слогах",
      path: "/games/find-turtles",
      imageUrl: findTurtlesImg,
      unique_id: 70,
    },

    {
      id: 71,
      title: "Чаепитие",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Звук 'Ч' в начале слова и в словосочетаниях",
      path: "/games/tea-party",
      imageUrl: teaParyImg,
      unique_id: 71,
    },

    {
      id: 72,
      title: "Доббль",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Звук 'Р', зрительное внимание",
      path: "/games/dobble",
      imageUrl: dobbleImg,
      unique_id: 72,
    },

    {
      id: 73,
      title: "Заяц спешит в гости",
      category: "Память, внимание и логика",
      subcategory: "Зрительное внимание",
      description: "Найди как можно больше морковок за 30 секунд",
      path: "/games/rabbit-in-guests",
      imageUrl: rabbitInGuestsImg,
      unique_id: 73,
    },

    {
      id: 74,
      title: "Новогодние носочки",
      category: "Память, внимание и логика",
      subcategory: "Зрительное внимание",
      description: "Найди пару для каждого носка",
      path: "/games/christmas-socks",
      imageUrl: christmasSocksImg,
      unique_id: 74,
    },

    {
      id: 75,
      title: "Ящерицы и хвосты",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Звук 'Щ', в слогах",
      path: "/games/lizards-and-tails",
      imageUrl: lizsrdsAndTailsImg,
      unique_id: 75,
    },

    {
      id: 76,
      title: "Рыбный рынок",
      category: "Звукопроизношение",
      subcategory: "Дифференциация звуков",
      description: "Дифференциация звуков 'Р' и 'Рь' в слогах",
      path: "/games/fish-market",
      imageUrl: fishMarketImg,
      unique_id: 76,
    },

    {
      id: 77,
      title: "Ведьмины заклинания",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука 'З'",
      path: "/games/witch-spells",
      imageUrl: witchSpellsImg,
      unique_id: 77,
    },

    {
      id: 78,
      title: "Играем на пианино",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука 'Ль'",
      path: "/games/play-piano",
      imageUrl: playPianoImg,
      unique_id: 85,
    },

    {
      id: 79,
      title: "Цапля на болоте",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука 'Ц' в слогах",
      path: "/games/heron-in-swamp",
      imageUrl: heronInSwampImg,
      unique_id: 79,
    },

    {
      id: 80,
      title: "Большое производство",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука 'Рь' в словах",
      path: "/games/big-manufacture",
      imageUrl: bigManufactureImg,
      unique_id: 83,
    },

    // {
    //   id: 81,
    //   title: "Список продуктов",
    //   category: "Звукопроизношение",
    //   subcategory: "Автоматизация звуков",
    //   description: "Автоматизация звука 'Рь' в словах",
    //   path: "/games/shopping-list",
    //   imageUrl: shoppingListImg,
    //   unique_id: 84,
    // },

    {
      id: 82,
      title: "Знакомство с грибами",
      category: "Развитие речи",
      subcategory: "Лексика",
      description: "Знакомство с грибами",
      path: "/games/acquaintance-with-mushrooms",
      imageUrl: acquaintanceMushroomsImg,
      unique_id: 80,
    },

    {
      id: 83,
      title: "Лесная жизнь",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука 'Л' в словах",
      path: "/games/forest-life",
      imageUrl: forestLifeImg,
      unique_id: 82,
    },

    {
      id: 84,
      title: "Мебельный домик",
      category: "Развитие речи",
      subcategory: "Лексика",
      description: "Знакомство с мебелью",
      path: "/games/furniture-house",
      imageUrl: furnitureHouseImg,
      unique_id: 81,
    },

    {
      id: 85,
      title: "Львиная охота",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука 'Ль' в начале слова",
      path: "/games/lion-hunt",
      imageUrl: lionHuntImg,
      unique_id: 86,
    },

    {
      id: 86,
      title: "Тающие льдинки",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука 'Ль' в конце слова",
      path: "/games/melting-ice",
      imageUrl: meltingIceImg,
      unique_id: 87,
    },

    {
      id: 87,
      title: "Бессонная ночь",
      category: "Память, внимание и логика",
      subcategory: "Логические задачи",
      description: "Что сначала, что потом",
      path: "/games/slepless-night",
      imageUrl: sleplessNightImg,
      unique_id: 91,
    },

    {
      id: 88,
      title: "Обучающие карточки по звукам музыкальных инструментов",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description:
        "Познакомьте ребенка с разновидностями звуков просто нажимая на картинки",
      path: "/games/musical-instruments-sounds-tutorial",
      imageUrl: musicalSoundsTutorialImg,
      unique_id: 1000,
    },
    {
      id: 89,
      title: "Обучающие карточки по звукам 'за городом'",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description:
        "Познакомьте ребенка с разновидностями звуков просто нажимая на картинки",
      path: "/games/countryside-sounds-tutorial",
      imageUrl: countrysideSoundsTutorialImg,
      unique_id: 1001,
    },

    {
      id: 90,
      title: "Обучающие карточки по звукам транспорта",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description:
        "Познакомьте ребенка с разновидностями звуков просто нажимая на картинки",
      path: "/games/transport-sounds-tutorial",
      imageUrl: transportSoundsTutorialImg,
      unique_id: 1002,
    },
    {
      id: 91,
      title: "Обучающие карточки по звукам животных",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description:
        "Познакомьте ребенка с разновидностями звуков просто нажимая на картинки",
      path: "/games/animals-sounds-tutorial",
      imageUrl: animalsSoundsTutorialImg,
      unique_id: 1003,
    },
    {
      id: 92,
      title: "Обучающие карточки по звукам бытовых предметов",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description:
        "Познакомьте ребенка с разновидностями звуков просто нажимая на картинки",
      path: "/games/home-appliances-tutorial",
      imageUrl: homeApplSoundsTutorialImg,
      unique_id: 1004,
    },

    {
      id: 93,
      title: "Обучающие карточки по звукам птиц",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description:
        "Познакомьте ребенка с разновидностями звуков просто нажимая на картинки",
      path: "/games/birds-sounds-tutorial",
      imageUrl: birdsSoundsTutorialImg,
      unique_id: 1005,
    },
    {
      id: 94,
      title: "Обучающие карточки по звукам природы",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description:
        "Познакомьте ребенка с разновидностями звуков просто нажимая на картинки",
      path: "/games/nature-sounds-tutorial",
      imageUrl: natureSoundsTutorialImg,
      unique_id: 1006,
    },

    {
      id: 95,
      title: "Обучающие карточки по звукам насекомых",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description:
        "Познакомьте ребенка с разновидностями звуков просто нажимая на картинки",
      path: "/games/insects-sounds-tutorial",
      imageUrl: insectsSoundsTutorialImg,
      unique_id: 1007,
    },
    {
      id: 96,
      title: "Обучающие карточки по звукам на улице",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description:
        "Познакомьте ребенка с разновидностями звуков просто нажимая на картинки",
      path: "/games/street-sounds-tutorial",
      imageUrl: streetSoundTutorialImg,
      unique_id: 1008,
    },
    {
      id: 97,
      title: "Обучающие карточки по звукам 'эмоции людей'",
      category: "Слуховое восприятие",
      subcategory: "Неречевые звуки",
      description:
        "Познакомьте ребенка с разновидностями звуков просто нажимая на картинки",
      path: "/games/emotions-sounds-tutorial",
      imageUrl: emotionsSoundTutorialImg,
      unique_id: 1009,
    },

    {
      id: 98,
      title: "Поиграем в игрушки",
      category: "Развитие речи",
      subcategory: "Грамматика",
      description: "Уменьшительно-ласкательные слова",
      path: "/games/lets-play-with-toys",
      imageUrl: letsPlayWithToysImg,
      unique_id: 90,
    },

    {
      id: 99,
      title: "Курочка 'Ко-Ко'",
      category: "Звукопроизношение",
      subcategory: "Автоматизация звуков",
      description: "Автоматизация звука 'К' в слогах",
      path: "/games/chicken-ko-ko",
      imageUrl: chickenKoKoImg,
      unique_id: 92,
    },

    {
      id: 100,
      title: "Хитрые весы",
      category: "Математика",
      subcategory: "Сравнение",
      description: "Что тяжелее, а что легче?",
      path: "/games/tricky-scales",
      imageUrl: trickyScalesImg,
      unique_id: 95,
    },

    {
      id: 101,
      title: "Хитрые весы",
      category: "Развитие речи",
      subcategory: "Слуховое внимание", // уточнить этот раздел
      description: "Что тяжелее, а что легче?",
      path: "/games/tricky-scales",
      imageUrl: trickyScalesImg,
      unique_id: 95,
    },

    {
      id: 102,
      title: "Помоги повару упаковать заказ",
      category: "Математика",
      subcategory: "Фигуры",
      description: "Знакомство с фигурами",
      path: "/games/help-cooker",
      imageUrl: helpCookerImg,
      unique_id: 49,
    },

    {
      id: 103,
      title: "Разложи покупки по пакетам",
      category: "Математика",
      subcategory: "Фигуры",
      description: "Знакомство с фигурами",
      path: "/games/sort-purchases-into-bags",
      imageUrl: sortPurchasesIntoBagsImg,
      unique_id: 50,
    },

    // {
    //   id: 104,
    //   title: "Доббль-иЛЛюминатор",
    //   category: "Звукопроизношение",
    //   subcategory: "Автоматизация звуков",
    //   description: "Звук 'Л'",
    //   path: "/games/dobble-l",
    //   imageUrl: dobbleLImg,
    //   unique_id: 96,
    // },

    // {
    //   id: 105,
    //   title: "Доббль-иЛЛюминатор",
    //   category: "Память, внимание и логика",
    //   subcategory: "Зрительное внимание",
    //   description: "Звук 'Л'",
    //   path: "/games/dobble-l",
    //   imageUrl: dobbleLImg,
    //   unique_id: 96,
    // },

    // {
    //   id: 106,
    //   title: "Тяжелее-легче",
    //   category: "Математика",
    //   subcategory: "Сравнение",
    //   description: "Что или кто тяжелее или легче?",
    //   path: "/games/heavier-lighter",
    //   imageUrl: heavierLighterImg,
    //   unique_id: 94,
    // },

    // {
    //   id: 107,
    //   title: "Доббль-СССокровища",
    //   category: "Звукопроизношение",
    //   subcategory: "Автоматизация звуков",
    //   description: "Звук 'С'",
    //   path: "/games/dobble-c",
    //   imageUrl: dobbleCImg,
    //   unique_id: 100,
    // },

    // {
    //   id: 108,
    //   title: "Доббль-СССокровища",
    //   category: "Память, внимание и логика",
    //   subcategory: "Зрительное внимание",
    //   description: "Звук 'С'",
    //   path: "/games/dobble-c",
    //   imageUrl: dobbleCImg,
    //   unique_id: 100,
    // },

    // {
    //   id: 109,
    //   title: "Секретное письмо",
    //   category: "Звукопроизношение",
    //   subcategory: "Автоматизация звуков",
    //   description: "Автоматизация звука 'Ль' в словах и предложениях",
    //   path: "/games/secret-letter",
    //   imageUrl: secretLetterImg,
    //   unique_id: 93,
    // },

    // {
    //   id: 110,
    //   title: "Секретное письмо",
    //   category: "Развитие речи",
    //   subcategory: "Связная речь",
    //   description: "Автоматизация звука 'Ль' в словах и предложениях",
    //   path: "/games/secret-letter",
    //   imageUrl: secretLetterImg,
    //   unique_id: 93,
    // },

    // {
    //   id: 110,
    //   title: "Доббль-ШШШапито",
    //   category: "Звукопроизношение",
    //   subcategory: "Автоматизация звуков",
    //   description: "Звук 'Ш'",
    //   path: "/games/dobble-sh",
    //   imageUrl: dobbleShImg,
    //   unique_id: 98,
    // },

    // {
    //   id: 111,
    //   title: "Доббль-ШШШапито",
    //   category: "Память, внимание и логика",
    //   subcategory: "Зрительное внимание",
    //   description: "Звук 'Ш'",
    //   path: "/games/dobble-sh",
    //   imageUrl: dobbleShImg,
    //   unique_id: 98,
    // },
    // {
    //   id: 112,
    //   title: "Львенок и его уроки",
    //   category: "Звукопроизношение",
    //   subcategory: "Артикуляционная гимнастика",
    //   description: "Артикуляционная гимнастика на звук 'Л' и 'Ль'",
    //   path: "/games/lion-and-his-lessons",
    //   imageUrl: lionAndHisLessonsImg,
    //   unique_id: 88,
    // },

    //     {
    //   id: 113,
    //   title: "Доббль-БББеседка",
    //   category: "Звукопроизношение",
    //   subcategory: "Автоматизация звуков",
    //   description: "Звук 'Б'",
    //   path: "/games/dobble-b",
    //   imageUrl: dobbleBImg,
    //   unique_id: 97,
    // },

    // {
    //   id: 114,
    //   title: "Доббль-БББеседка",
    //   category: "Память, внимание и логика",
    //   subcategory: "Зрительное внимание",
    //   description: "Звук 'Б'",
    //   path: "/games/dobble-b",
    //   imageUrl: dobbleBImg,
    //   unique_id: 97,
    // },

    // {
    //   id: 115,
    //   title: "Доббль-ЖЖЖелезная дорога",
    //   category: "Звукопроизношение",
    //   subcategory: "Автоматизация звуков",
    //   description: "Звук 'Ж'",
    //   path: "/games/dobble-zh",
    //   imageUrl: dobbleZhImg,
    //   unique_id: 99,
    // },

    // {
    //   id: 116,
    //   title: "Доббль-ЖЖЖелезная дорога",
    //   category: "Память, внимание и логика",
    //   subcategory: "Зрительное внимание",
    //   description: "Звук 'Ж'",
    //   path: "/games/dobble-zh",
    //   imageUrl: dobbleZhImg,
    //   unique_id: 99,
    // },

    // {
    //   id: 117,
    //   title: "Доббль-ЗЗЗвездные врата",
    //   category: "Звукопроизношение",
    //   subcategory: "Автоматизация звуков",
    //   description: "Звук 'З'",
    //   path: "/games/dobble-z",
    //   imageUrl: dobbleZImg,
    //   unique_id: 101,
    // },

    // {
    //   id: 118,
    //   title: "Доббль-ЗЗЗвездные врата",
    //   category: "Память, внимание и логика",
    //   subcategory: "Зрительное внимание",
    //   description: "Звук 'З'",
    //   path: "/games/dobble-z",
    //   imageUrl: dobbleZImg,
    //   unique_id: 101,
    // },
  ];

  return (
    <div>
      <div className={styles.mobileMessage}>
        Извините, эта страница доступна только на планшетах и компьютерах.
      </div>
      <div className={styles.test}>
        <GamesMenuNew
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          gamesData={gamesData}
        />
        <GamesListNew gamesData={gamesData} activeCategory={activeCategory} />
      </div>
    </div>
  );
};

export default Games;
